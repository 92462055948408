
[data-tooltip],
.tooltip{
    @extend %position-relative;
    @include opacity(1);
    @include z-index(0);
    @include padding(5px);
    @include text-align(center);
    height: 32px;
    width: 32px;

    &:hover,
    &:focus {
        &:before,
        &:after {
            @include visible(visible);
            @include opacity(1);
        }
    }

    &:before,
    &:after {
        @extend %position-absolute;
        @include transform(0);
        @include visible(hidden);
    }

    &:before {
        @include background($transparent);
        @include margin(0, 0, -11px, -6px);
        @include border-all(6px, solid, $transparent);
        @include z-index(1001);
        @include left(0);
        border-top-color: $darkest-xx;
        content: "";
    }

    &:after {
        @include font-size(14);
        @include font-color($white);
        @include bgcolor($darkest-xx);
        @include border-radius(5px);
        @include padding(5px 8px);
        @include z-index(1000);
        content: attr(data-tooltip);
        width: auto;
        line-height: 1.2;
        white-space: nowrap;
    }
}

.tooltip {

    //Bottom Direction
    &.bottom {

        &:hover,
        &:focus {

            &:before,
            &:after {
                @include translate (0, 6px);
            }
        }

        &:after {
            @include top(100%);
            @include left(-100%);
            @include text-align(center);
            width: 90px;
            white-space: pre-wrap;
        }

        &:before {
            @include margin(0, 0, -12px, -6px);
            @include left(50%);
            @include bottom(12px);
            border-bottom-color: $darkest-xx;
            border-top-color: $transparent;
        }
        &.sm{
            &:after {
                @include left(-42%);
                @include text-align(center);
                width: 56px;
            }
        }
        &.lg{
            &:after {
                @include left(-185%);
                @include text-align(center);
                width: 150px;
            }
        }
        &.bottom-right{
            &:after {
                @include left(-190%);
            }
        }
        &.bottom-left{
            &:after {
                @include left(0);
            }
        }

    }

    //Left Direction
    &.left {
        &:hover,
        &:focus {

            &:before,
            &:after {
                @include translate (-9px, 0);
            }
        }

        &:after {
            @include top(2px);
            @include right(125%);
        }

        &:before {
            @include margin-all(-6px 0 0 0);
            @include left(-9px);
            @include top(50%);
            border-left-color: $darkest-xx;
            border-top-color: $transparent;
        }
    }

    //Right Direction
    &.right {
        &:hover,
        &:focus {

            &:before,
            &:after {
                @include translate (9px, 0);
            }
        }


        &:after {
            @include top(2px);
            @include left(125%);
        }

        &:before {
            @include margin-all(-6px 0 0 0);
            @include right(-8px);
            @include top(50%);
            border-right-color: $darkest-xx;
            border-top-color: $transparent;
        }
    }

    //Top Direction
    &.top {
        &:hover,
        &:focus {

            &:before,
            &:after {
                @include translate (0, -6px);
            }
        }

        &:after {
            @include bottom(100%);
            @include left(-100%);
            @include text-align(center);
            width: 90px;
            white-space: pre-wrap;
        }

        &:before {
            @include margin-all(0 0 0 -6px);
            @include left(50%);
            @include top(0);
            border-top-color: $darkest-xx;
            border-bottom-color: $transparent;  
        }
        &.sm{
            &:after {
                @include left(-42%);
                @include text-align(center);
                width: 56px;
            }
        }
        &.lg{
            &:after {
                @include left(-185%);
                @include text-align(center);
                width: 150px;
            }
        }
        &.top-right{
            &:after {
                @include left(-190%);
            }
        }
        &.top-left{
            &:after {
                @include left(0);
            }
        }
    }
}
