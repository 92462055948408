@mixin padding($value){
    padding: $value;
}

@mixin padding-all($top, $right, $bottom, $left){
    padding-top: $top;
    padding-right: $right;
    padding-bottom: $bottom;
    padding-left: $left;
}

@mixin padding-indv($direction, $value){
    padding-#{$direction}: $value;
}


.p{
    &-r{
        &-5{
            @include padding-indv('right', 5px);
        }
    }
}
