.error{
    &--txt{
        @extend .full-width;
        min-height: 22px;
        
        p{
            @include font-color($danger);
            &:before{
                @include font-color($danger);
                @include font-size(12);
                @include position(relative);
                @include top(-1px);
                @include padding-indv('right', 3px);
                font-family: "Font Awesome 5 Free", Arial, Helvetica, sans-serif;
                content: "\F071";
            }
        }
    }

    &-page{
        @include text-align(center);
        h1{
            @include font-size(40);
            @include font-color($black);
            line-height: 3.2rem;
        }

        h2{
            @include font-size(20);
        }

        img{
            width: 60%;
        }
    }

    &-msg{
        @extend %position-relative;
        @include margin-indv('top', -49px);
    }
}
