.form{
    &-control{
        @include font-color($black);
        @include font-size(14);

        &:focus{
            @extend %form-control-ui-focus;
            @include font-color($black);
        }

        &:disabled,
        &[readonly]{
            @extend .bg-lighter;

            &:hover,
            &:focus,
            &:active{
                @extend .cursor-deny;
            }
        }
    }

    &-group{
        @extend %position-relative;

        label{
            @include font-color($black);
            line-height: 19px;
        }

        .custom{
            &-select{
                @extend %form-control-ui;
                @include font-color($darkest-x);

                &:focus{
                    @extend %form-control-ui-focus;
                    @include box-shadow-none;
                }

                &.opened{
                   background-image: none; 
                }
            }
        }

        .form{
            &-control{
                @extend %form-control-ui;

                &:hover {
                    &:before {
                      background-color: transparentize($color: $lightest, $amount: .25);
                    }
                }

                &-file {
                    @extend %position-relative;
                    @extend .full-width;
                    @extend .full-height;
                    @extend .hidden;
                    outline: none;

                    &:before,
                    &::before {
                        @extend %position-absolute;
                        @extend .visible;
                        @extend .full-width;
                        @include top(0);
                        @include left(0);
                        @include opacity(1);
                        @include border-radius(4px);
                        @include border-all(1px, dashed, $darkest);
                        content: '';
                        background: url('./assets/images/upload.jpg') no-repeat center 27px $lightest;
                        background-size: 27px;
                        min-height: 110px;
                        text-align: center;
                        overflow: hidden;
                        transition: all .3s cubic-bezier(.25, .8, .25, 1);
                    }

                    &:after,
                    &::after {
                        @extend %position-absolute;
                        @extend .full-width;
                        @extend .visible;
                        @include top(4rem);
                        @include left(0);
                        @include opacity(1);
                        content: attr(data-title);
                        text-align: center;
                        overflow: hidden;
                        transition: all .3s cubic-bezier(.25, .8, .25, 1);
                    }
                }

                &.ng{
                    &-touched{
                        &.ng{
                            &-invalid{
                                @include border-all(1px, solid, $danger);
                            }
                        }
                    }
                }
            }
        }

        .upload{
            &-file{
                @extend %position-absolute;
                @extend .text-underline;
                @include padding-indv('top', 5rem);
                @include padding-indv('bottom', .5rem);
                @include z-index(1);
                width: 100%;
                text-align: center;
            }
        }

        &.upload{
            &-container{
                @include margin-indv('bottom', 4rem);
                &.form{
                    &-group{
                        .form-control-file{
                            visibility: visible;

                            input[type='file']{
                                opacity: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    &-label{
        @include margin-indv(bottom, .5rem);
        line-height: 19px;
    }
}

.custom{
    &-control{
        &-label{
            &:hover,
            &:focus{
                &::after{
                    @include border-all(1px, solid, $darkest);
                }
            }

            &:before{
                @include bgcolor($white);
            }
            &::after{
                @include border-all(1px, solid, $darker);
                transition: background-color .5s;
            }
        }

        &-input{
            &:focus{
                &+label{
                    &::before{
                        animation: ripple .5s ease-out;
                        border-color: hsl(216, 94%, 73%);
                        border-width: 3px;
                        box-shadow: inset 0 0 0 1px hsl(216, 80%, 50%);
                    }
                }
            }

            &:checked{
                &~.custom{
                    &-control{
                        &-label{
                            &::before{
                                @include bgcolor($success);
                                @include border-color($success);
                            }

                            &::after{
                                @include bgcolor($primary);
                            }
                        }
                    }
                }
            }
        }
    }

    &-radio{
        .custom{
            &-control{
                &-label{
                    &:before{
                        @include top(1px);
                    }

                    &::after{
                        @include border-radius(50%);
                        @include top(9px);
                        @include left(-20px);
                        transform: translate(-4px, -50%);
                    }
                }
            }
        }
    }

    &-checkbox{
        .custom{
            &-control{
                &-label{
                    &:before{
                        @include border-radius(0);
                    }

                    &::before,
                    &::after{
                        @include top(2px);
                    }
                }
            }
        }
    }
}

@keyframes ripple {
  0% {
      @include opacity(1);
      transform: scale(0, 0);
  }
  20% {
      @include opacity(.5);
      transform: scale(1, 1);
  }
  100% {
      @include opacity(0);
      transform: scale(2, 2);
    }
}

textarea{
    resize: none
}

input{
    &::-ms-clear{
        @include display(none);
    }

    &[type="search"]{
        &::-webkit-search-cancel-button{
            @include display(none);
        }
    }
}
