// button{
//   &.focus,
//   &:focus{
//     outline: 1px dashed $black;
//   }
// }

.btn {
  @extend %border-transparent;

  &.focus,
  &:focus {
    @include box-shadow-none;
    // outline: 1px dashed $black;
  }

  &.disabled,
  &:disabled,
  &:not(:disabled):not(.disabled),
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    @extend %border-transparent;
  }

  &.disabled,
  &:disabled {
    @extend %border-transparent;
    @include bgcolor($light);
    @include font-color($secondary);
    cursor: not-allowed;

    &:hover,
    &:focus {
      @include box-shadow-none;
      @include bgcolor($light);
      @include font-color($secondary);
    }
  }

  &:hover,
  &:focus {
    @extend %border-transparent;
  }

  &-primary {
    @include bgcolor($primary);

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled).active:focus,
    &:not(:disabled):not(.disabled):active:focus {
      @include font-color($white);
      background-color: scale-color($primary, $lightness: -20%);
    }

    &:hover,
    &:focus,
    &:active {
      @include box-shadow(1px, 4px, 5px, -2px, rgba(0, 110, 255, .4));
      background-color: scale-color($primary, $lightness: -20%);
    }
  }

  &-secondary,
  &-light {
    @include font-color($secondary);
    @include bgcolor($light);

    &:hover,
    &:focus,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active {
      @include font-color($secondary);
      background-color: transparentize($color: $light, $amount: .25);
    }
  }

  &-success {
    @include bgcolor($green);

    &:hover,
    &:focus,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active {
      background-color: transparentize($color: $green, $amount: .25);
    }
  }

  &-danger {
    @include bgcolor($pink);

    &:hover,
    &:focus,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active {
      @include box-shadow-error-alert;
      background-color: scale-color($notify, $lightness: -20%);
    }
  }

  &-dark {
    @include bgcolor($secondary);

    &:hover,
    &:focus,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active {
      background-color: transparentize($color: $secondary, $amount: .25);
    }
  }

  &-cancel {
    @include bgcolor(transparent);
    @include font-color($darkest-xx);
    @include font-size(14);

    &:hover,
    &:focus,
    &:active,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active {
      color: transparentize($color: $darkest-xx, $amount: .25);
    }
  }

  &-sm {
    @include font-size(12);
    @include padding(.25rem .9rem);

    @include media("screen", ">minXxlarge") {
      @include font-size(15);
    }
  }

  &-lg {
    @include padding(.5rem 1.8rem);
  }

  &-group {
    &-toggle {
      @include font-size(15);
      @include border-radius(4px);
      @include border-all(1px, solid, $dark);
      @include z-index(0);

      .btn {
        @include float(left);

        &-secondary {
          @include bgcolor($white);
          @include box-shadow(0, 0, 15px, 0, rgba(0, 0, 0, .15));
          z-index: 2;

          &:focus,
          &:active,
          &.active {
            @include box-shadow-none;
            @include border-all(1px, solid, $primary);
            z-index: 1;
          }

          &:not(:disabled):not(.disabled) {

            &.active,
            &:active {
              @extend %border-transparent;
              @include bgcolor($tertiary);
            }

            &.active,
            &:active {
              &:focus {
                @include box-shadow-none;
                @include border-all(1px, solid, $primary);
              }
            }
          }

          &--mid {
            border-left: 1px solid $dark;
          }

          &.disabled {
            @include box-shadow-none;
            @include bgcolor($darker);
            @include font-color($secondary);
          }
        }

        &-xs {
          @include font-size(13);
          @include padding-all(3px, 10px, 1px, 10px);
        }
      }
    }

    &>.btn:not(:last-child):not(.dropdown-toggle) {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
  }

  &-accept {
    @include font-color($white);
    @include padding(5px 25px);
    background-color: transparentize($color: $black, $amount: .75);

    &:hover,
    &:focus,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active {
      background-color: transparentize($color: $black, $amount: .7);

    }

    &.focus,
    &:focus {
      @include box-shadow(0, 0, 0, 0.2rem, rgba(255, 255, 255, 0.5));

    }
  }

  &-decline {
    @include bgcolor(transparent);
    @include font-color($white);

    &:hover {
      @include font-color($light);
    }

    &.focus,
    &:focus {
      @include font-color($light);
      @include box-shadow(0, 0, 0, 0.2rem, rgba(255, 255, 255, 0.5));

    }
  }

  &-icon {
    @include bgcolor(transparent);
    @include border-none(none);
    @include font-color($secondary);

    &:hover {
      @include font-color($primary);
    }
  }

  &-clear {
    @include bgcolor(transparent);
    @include font-size(14);

    &:hover,
    &:focus {
      @include font-color($primary);
      @include bgcolor(transparent);
    }

    &.disabled,
    &:disabled {
      @extend %border-transparent;
      @include bgcolor($light);
      @include font-color($secondary);
      cursor: not-allowed;
      opacity: .65;
    }
  }

  &-filter {
    &::after {
      @include margin-indv('top', 8px);
    }
  }

  &-add-more {
    @include display(inline-block);
    @include padding(0 5px);
  }

  &-drag {
    @include margin-indv('top', 3px);
    opacity: .4;

    &:hover,
    &:focus,
    &:active {
      opacity: 1;
    }

    &:not(:disabled) {
      &:not(.disabled) {
        cursor: grab;
        cursor: -webkit-grab;

        &:focus,
        &:active {
          cursor: -webkit-grabbing;
          cursor: grabbing;
        }
      }
    }
  }

  &-link {
    &-text {
      @include font-weight(600);
      @include font-color($darkest-xx);
      @include bgcolor(transparent);
      @include font-size(14);
      @include padding(0);
    }
  }

  &__filter {
    @include border-none(none);
    @include font-color($secondary);
    line-height: 19px;
  }

  &-group>.btn:not(:first-child) {
    margin-left: 0;
  }

  &-back {
    .ic-flip {
      @include font-color($darkest-x);
      font-size: 10px !important;
    }

    &:hover,
    &:focus {
      @include font-color($primary);

      .ic-flip {
        @include font-color($primary);
      }
    }
  }
}
