.drag{
    @extend .cursor-grab;

    &:hover,
    &:focus{
        .handle
        {
            @extend %transition-fade-active;
        }
    }

    &:active{
        @extend .cursor-grabbing;
        @include border-all(1px, solid, $primary);
        @include box-shadow-success;
        @include font-color($black);
    }

    .handle{
        @include opacity(.4);
        transition: all ease-in-out .3s;
    }

    .el{
        @extend %position-relative;
        @include padding(0 5px);
    }
    .more-option{
        @extend %position-relative;
        @include padding-all(0, 16px, 0,5px);
    }
}

//dragula implemented classes
.gu{
    &-unselectable {
        @extend .cursor-grabbing;
        @include font-color($secondary);

        .drag{
            @extend .cursor-grabbing;
        }
    }

    &-transit {
        @extend .drag, :active;
    }
}

.draggable-table{
    .gu-transit{
        @include background($white);
        transform: scale(1.15);
        z-index: 1;
    }
}
