@mixin font-size($size) {
    //add font value in PX it will convert to REM
    //also for older browser where rem is not supported it will render PX
    font-size: $size + px;
    font-size: ($size/16) + rem;
}

@mixin font-color($value) {
    color: $value;
}

// light: 300; regular: 400; semi-bold: 600;
@mixin font-weight($weight){
    font-weight: $weight;
}

.font{
    &-0x{
        @include font-size(10);
    }
    &-1x{
        @include font-size(12);
    }

    &-2x{
        @include font-size(14);
    }

    &-3x{
        @include font-size(16);
    }

    &-4x{
        @include font-size(18);
    }

    &-5x{
        @include font-size(20);
    }

    &-13x{
        @include font-size(36);
    }
}
