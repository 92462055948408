.exemplar{
    &-container{
        @include margin-all(0 -15px);
    }

    &-listing{
        @include padding(10px 0);
        @include font-color($secondary);

        &:not(:last-child){
            @include border-indv('bottom', 1px, solid, $darker);
        }

        .title{
            @include font-color($black);
        }
    }
}
