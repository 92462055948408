.date-picker{
    .mydrp,
    .mydp {
        .range {
            @include bgcolor($lighter);
        }
        .selectedyear, 
        .selectedmonth,
        .selectedday{
            .yearvalue,  .monthvalue, .datevalue{
                @include bgcolor(transparent);
            }
        } 
        .headerbtn, 
        .monthlabel, 
        .yearchangebtn, 
        .yearlabel{
            &:focus{
                @include font-color($darkest-xxx);
            }
        }
        .selection {
            @extend %form-control-ui;
    
            &:focus {
                @extend %form-control-ui-focus;
            }
        }
        .selectorarrow{
            @include margin-indv('top', 0);
        }
        .titlearea{
            @include text-align(left);
            .titleareatxt{
                @include padding-indv('left',10px);
            }
            
        }
        .btnclear,
        .btnpicker{
            @include bgcolor(transparent);
            @include margin-indv('right', -1px);
        }
        .headerbtn,
        .headerclearbtn,
        .headerokbtn,
        .headerlabelbtn {
            &:focus{
                @include bgcolor($dark);
                @include border-radius(2px);
            }
        }
        .headerclearbtn,
        .headerokbtn{
            @include border-none(none);
        }
        .selector{
            @include right(0);
            @include margin-indv('top', 5px);
            height: 290px !important;
            width: 315px !important;
        }
        .btnclearenabled,
        .btnpickerenabled,
        .headerclearbtnenabled,
        .headerokbtnenabled {
            &:hover,
            &:focus {
                @include bgcolor($dark);
            }

            &.focus,
            &:focus{
                outline: 1px dashed $black;
            }
        }
        
        .selectorarrowleft{
            &:before,
            &:after{
                @include display(none);
            }
        }
        .mydrpicon{
            @include font-color($darkest-xx);
            &:hover{
                @include font-color($black);
            }
        }
        .markcurrday,
        .markcurrmonth,
        .markcurryear{
            @extend %font-weight-semi-bold;
            text-decoration: none;
        }
        .weekdaytitle{
            @include font-color($darkest-x);
            @include bgcolor($white);
        }
        .daycell{
            @include padding-all(10px, 4px, 10px, 4px);
        }
        .caltable, 
        .daycell, 
        .monthcell, 
        .monthtable, 
        .yearcell, 
        .yeartable{
            @include font-color($darkest-xx);
        }
        
        .currmonth{
            .sunday{
                @include font-color($danger);
            }
        }
        .currmonth,
        .prevmonth,
        .nextmonth{
            @include bgcolor(transparent);
            &.selecteddayend,
            &.selecteddaybegin{
                &.currmonth{
                    &.datevalue{
                        @include bgcolor(inherit);
                        @include font-color($primary);
                    }
                }
            }
            

            &:hover,
            &:focus{
                @include bgcolor($light);

                .currmonth{
                    @include bgcolor($light);
                }
            }
            
            &.range{
                @include bgcolor($lighter);
                    .datevalue{
                        &.currmonth,
                        &.nextmonth,
                        &.prevmonth{
                        @include bgcolor($lighter);
                        @include font-color($primary);
                    }
                }
            }
            
        }
        .prevmonth,
        .nextmonth{
        &:hover{
                @include bgcolor(transparent);
            }
        }
        .daycell {
            &.disabled{
                @extend .cursor-deny;
                opacity: .8;
                .currmonth{
                    &:hover{
                        @include bgcolor(transparent);
                    }
                }
            }
        }
    }
}

.cdk{
    &-overlay{
        &-container,
        &-backdrop{
            z-index: 1051;
        }

        &-connected-position-bounding-box,
        &-pane{
            z-index: 1052;
        }
    }
}

.owl-dt-calendar-table .owl-dt-calendar-cell-content{
    border-radius: 1px;
}

.owl-dt-calendar-table .owl-dt-calendar-cell{
    border: 1px solid $tertiary;
}

.owl-dt-calendar-table .owl-dt-calendar-cell-in-range{
    background: transparent;
}

.owl-dt-calendar-table .owl-dt-calendar-cell-selected{
    @include bgcolor($primary);
    @include font-color($white);
}