//all side border radius
@mixin border-radius($radius){
    border-radius: $radius;
}

// individual border-radius
@mixin border-radius-top($radius) {
    border-top-right-radius: $radius;
    border-top-left-radius: $radius;
}

@mixin border-radius-right($radius) {
    border-bottom-right-radius: $radius;
    border-top-right-radius: $radius;
}

@mixin border-radius-bottom($radius) {
    border-bottom-right-radius: $radius;
    border-bottom-left-radius: $radius;
}

@mixin border-radius-left($radius) {
    border-bottom-left-radius: $radius;
    border-top-left-radius: $radius;
}

@mixin border-radii($topleft, $topright, $bottomright, $bottomleft) {
	border-top-left-radius: $topleft;
	border-top-right-radius: $topright;
	border-bottom-right-radius: $bottomright;
	border-bottom-left-radius: $bottomleft;
}
