.data{
    &-collection{
        &-list{
            @include padding(10px 15px);
            @include border-radius(5px);

            p{
                @include font-size(16);
                @include margin-indv('bottom', 8px);
            }
            .show{
                &-data{
                    @include border-indv('bottom', 1px, solid, $light);
                    @include bgcolor($lightest);

                    &:last-child{
                        @include border-none(none);
                    }
                }
            }
            .row{
                div[class^='col-']{
                    @include padding(5px 10px);
                }
            }

            .active{
                @include bgcolor($white);
                @include box-shadow-success;
                z-index: 1;
            }
        }
    }
}

.item{
    &-list{
        &.placeholder{
            &:empty{
                &:before{
                    content: 'Add List Item';
                }
            }
        }
    }
}
