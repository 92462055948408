.node{
    &-template{
        &-creation{
            @include padding-indv('top',40px);

            .node{
                &-type{
                    @include margin-all(0 15px);
                }
            }
        }

        &-preview{
            @include padding-indv('top', 80px);

            .name{
                @extend %font-weight-semi-bold;
                @include margin-indv('bottom', 0);
            }

            .description{
                @include font-color($secondary);
            }
        }
    }

    &-type{
        @extend %position-relative;
        @include border-indv(top, 1px ,solid, $light);
        @include padding(8px 0);

        &.drag{
            .el{
                @include top(2px);
            }
        }

        &.gu-transit{
            @include border-indv(top, 1px ,solid, $primary);
        }
    }
}
