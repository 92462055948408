.dropdown {
    &-menu {
        @include box-shadow-dropdown;
        @include padding-indv('left', 10px);
        @include padding-indv('right', 10px);
        @include border-radius(4px);
        @include font-size(14);
        @include z-index(1100);

        &.dropdown {
            &-wrap {
                max-width: 250px;

                .dropdown {
                    &-container {
                        max-height: 300px;
                        overflow-y: auto;
                        overflow-x: hidden;
                    }

                    &-item {
                        white-space: normal;
                    }

                }
            }
        }

        .btn {
            &:hover,
            &:focus,
            &:active {
                @include font-color($white);
                @include border-radius(4px);
                background-color: transparentize($color: $primary, $amount: .25);
            }

            &-clear {
                @include font-size(14);
            }
        }

        a {
            &:not([href]) {
                @include font-color($white);
            }

            &:hover,
            &:focus,
            &:active {
                @include font-color($white);
                @include border-radius(4px);
                background-color: transparentize($color: $primary, $amount: .25);
            }
        }

        .dropdown-item {
            @include padding(.25rem .5rem);

            &.disabled {
                @extend .cursor-deny;
                @include opacity(.5);

                &:hover {
                    @include bgcolor(inherit);
                }
            }
        }

        &-scrollable {
            max-height: 220px;
            overflow-y: auto;
            overflow-x: hidden;
        }
    }

    &-overflow {
        max-height: 16rem;
        overflow-y: auto;
        overflow-x: hidden;
    }

    &-filter {
        @include margin-indv('top', 15px);

        &__search {
            width: 12rem;

            .custom {
                &-control {
                    padding-left: 1.58rem;
                }
            }

        }
    }

    &-split {
        .show {
            &>.dropdown {
                &-toggle {
                    &:focus {
                        @include box-shadow-none;
                    }
                }
            }
        }

        .btn {
            @include padding-all(4px, 15px, 4px, 15px);

            &:first-child {
                cursor: auto;

                &:hover,
                &:focus {
                    @include box-shadow-none;
                    @include opacity(1);
                    @include bgcolor($notify);
                }
            }

            &:disabled {
                &:first-child {

                    &:hover,
                    &:focus {
                        @include bgcolor($light);
                        @include font-color($darkest-x);
                    }
                }
            }
        }

        .dropdown {
            &-toggle {
                &-split {
                    @include padding-all(4px, 12px, 4px, 12px);

                    &:after {
                        @extend %position-relative;
                        @include border-none(0);
                        @include top(2px);
                        content: '\e901';
                        font-family: $icon-font;
                    }
                }
            }

            &-menu {
                @include box-shadow-dropdown;
                @include padding(0);
                @include bgcolor($white);
                @include top(0px);
                @include margin-indv('top', 0);

                &:after {
                    @include border-none(0);
                }

                ul {
                    @include padding(0);
                    @include margin-all(0);
                    overflow: hidden;

                    li {
                        &:first-child {
                            @include border-radius-top(3px);
                        }

                        .btn {
                            &:hover,
                            &:focus,
                            &:active {
                                @include font-color($white);
                                @include bgcolor($primary);
                                outline: none;
                            }
                        }

                        &:hover,
                        &:focus,
                        &:active {
                            @include font-color($white);
                            @include bgcolor($primary);

                            .btn {
                                &:hover,
                                &:focus,
                                &:active {
                                    @include font-color($white);
                                    @include bgcolor($primary);
                                    outline: none;
                                }
                            }
                        }

                        &.active {
                            @include font-color($darkest);
                            @include bgcolor($lightest);
                            @include border-indv(bottom, 1px, solid, $darker);

                            &:hover,
                            &:focus,
                            &:active {
                                @include font-color($white);
                                @include bgcolor($primary);
                            }
                        }


                    }

                    &:first-child {
                        @include border-radius(4px 4px 0 0);
                    }

                    &:last-child {
                        @include border-radius(0 0 4px 4px);

                        &.active {
                            @extend %no-border-bottom;
                        }
                    }
                }
            }
        }
    }
}

.trimmed {
    max-width: 9rem;
}