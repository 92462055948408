%form-control-ui{
    @include border-color($darker);
    // @include box-shadow-inset;
    @include border-radius(3px);
}

%form-control-ui-focus{
    @include border-color($black);
    outline: 1px solid $black;
}
