.comment{
    .shadow{
        @include box-shadow(0,2px,10px,1px,rgba(0, 0, 0, .1));
    }

    .card{
        &-header{
            @include bgcolor($white);
        }

        // &-body{
        //     .content{
        //         @include padding-indv('left', 2.2rem);
        //         @include padding-indv('right', 2.2rem);
        //     }
        // }

        &-footer{
            @include bgcolor($tertiary);
            // @include padding-indv('left', 3.2rem);
        }
    }

    .avatar{
        width: 40px;
        height: 40px;
    }

    &-time,
    .status{
        @include font-color($secondary);
    }
}
