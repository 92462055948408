.progress{
    @include border-all(1px, solid, $light);
    @include padding(1px);
    @include bgcolor($tertiary);
    height: 12px;
    &.danger{
        .progress-bar{
            @include bgcolor($danger);
        }
    }

    &-bar{
        @include bgcolor($notify);
        @include border-radius(5px);
        height: 8px;
        &.danger{
            @include bgcolor($danger);
        }
        &.warning{
            @include bgcolor($warning);
        }
        &.success{
            @include bgcolor($success);
        }
    }
    &-primary{
        @include border-all(1px, solid, $dark);
        .progress{
            &-bar{
                @include bgcolor($primary);
            }
        }
    }
}
