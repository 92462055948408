@font-face {
  font-family: $icon-font;
  src:  url('./assets/fonts/icomoon.eot?c0wby6');
  src:  url('./assets/fonts/icomoon.eot?c0wby6#iefix') format('embedded-opentype'),
    url('./assets/fonts/icomoon.ttf?c0wby6') format('truetype'),
    url('./assets/fonts/icomoon.woff?c0wby6') format('woff'),
    url('./assets/fonts/icomoon.svg?c0wby6#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'], [class*=' icon-'] {
  // use !important to prevent issues with browser extensions that change fonts
  font-family: $icon-font;
  //speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  @include display(inline-block);
  // Better Font Rendering
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ico-collapse:before {
  content: '\e900';
}
.icon-ico-dropdown:before {
  content: '\e901';
}
.icon-ico-edit:before {
  content: '\e902';
}
.icon-ico-expand:before {
  content: '\e903';
}
.icon-ico-folder:before {
  content: '\e904';
}
.icon-ico-folder-open:before {
  content: '\e905';
}
.icon-ico-garbage:before {
  content: '\e906';
}
.icon-ico-help:before {
  content: '\e907';
}
.icon-ico-history:before {
  content: '\e908';
}
.icon-icon-chart:before {
  content: '\e909';
}
.icon-ico-next-arrow:before {
  content: '\e90a';
}
.icon-icon-in-progress:before {
  content: '\e90b';
}
.icon-icon-more-horiz:before {
  content: '\e90c';
}
.icon-ico-notifications:before {
  content: '\e90d';
}
.icon-icon-upload:before {
  content: '\e90e';
}
.icon-ico-prev-arrow:before {
  content: '\e90f';
}
.icon-ico-protip .path1:before {
  content: '\e910';
  color: rgb(248, 231, 28);
}
.icon-ico-protip .path2:before {
  content: '\e911';
  margin-left: -.8828125em;
  color: rgb(248, 231, 28);
}
.icon-ico-protip .path3:before {
  content: '\e912';
  margin-left: -.8828125em;
  color: rgb(66, 82, 110);
}
.icon-ico-standard:before {
  content: '\e913';
}
.icon-Shape:before {
  content: '\e914';
}
