.file{
    &-panel{
        @extend %position-relative;
        @include bgcolor($lightest);
        @include border-all(1px, solid, $light);
        @include border-radius(.3rem);
        height: 100%;

        &-header{
            @include padding(10px 15px);
            @include border-indv('bottom', 1px, solid, $light);
            h3{
                @include font-size(20);
            } 
        }
        &-list{
            @include padding(10px 0);
            @include border-indv('bottom', 1px, solid, $light);
            &:last-child{
                @include bgcolor($white);
                @include box-shadow-inset;
            }
            h4{
                @extend %font-weight-semi-bold;
                @include font-size(16);
                @include margin-indv('bottom',0);
            }
            p{
                @include font-color($darkest-xxxx);
                @include margin-indv('bottom',0);
            }
        }
        .exemplar{
            &-listing{ 
                @include border-indv('bottom', 1px, solid, $light);
                @include padding(10px 15px);

                &.active{
                    @include bgcolor($white);
                }
            }
        }
    }
    &-no{
        @extend %font-weight-semi-bold;
        @include font-size(18);
        @include padding(0 15px);
        @include margin-indv('top', -4px);
    }
}
