.axis{
    path,
    line{
        fill: none;
        stroke: $light !important;
        shape-rendering: crispEdges;
    }
} 

.x{
    &.axis{
    path {
            @include display(none);
        }
    }
}

.y{
    &.axis{
        line{
            fill: none;
        }

        path{
            @include display(none);
        }
    }
}
  
.line {
    fill: none;
    stroke-width: 1.5px;
}