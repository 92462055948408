#toast-container{
    @include margin-indv('top', 1rem);

    &>div{
        @include box-shadow(0, 3px, 6px, 0, rgba($black, .2));
        @include border-radius(6px);
        opacity: 1;
        
        &:hover,
        &:focus,
        &:active{
            @include box-shadow(0, 3px, 6px, 0, rgba($black, .2));
        }
    }
    
    &.toast-top-center{
        &>div{
            @include flex;
            @include padding(10px 0);
            justify-content: flex-start;
            align-items: center;
            width: 600px;
        }
    }

    &>.toast-error{
        background-image: url('~/assets/images/info.svg') !important;
        background-position: 20px 12px;
    }

    &>.toast-success{
        background-image: url('~/assets/images/success.svg') !important;
        background-position: 19px 12px;
    }
}

button{
    &.toast-close-button{
        @include right(21px);
        @include top(1px);
        @include border-radius(5px);
        background: url('~/assets/images/close.svg') no-repeat 3px 3px;
        background-size: 80%;
        height: 30px;
        width: 30px;
        order: 3;
        opacity: 1;
    
        span{
            @include display(none);
        }

        &:hover{
            @include border-radius(50%);
            opacity: 1;
        }
    
        &:focus{
            opacity: 1;
            outline: 1px dashed $white;
        }
    }
}

.toast{
    &-success{
        @include bgcolor($success);

        button{
            &:hover{
                @include bgcolor($success_hover);
            }

            .toast-close-button{
                @include bgcolor(transparent);
            }
        }
    }

    &-error{
        @include bgcolor($notify);

        button{
            &:hover{
                @include bgcolor($notify_hover);
            }
        }
    }

    &-message{
        @include padding(3px 1rem 0 3.8rem);
        width: 92%;
        order: 2;
        line-height: 1.4rem;
    }

    &-title {
        @include display(none);
    }
}
