@mixin margin-all($value){
    margin: $value;
}

@mixin margin-indv($direction, $value){
    margin-#{$direction}: $value;
}

@mixin margin($top, $right, $bottom, $left){
    margin-top: $top;
    margin-right: $right;
    margin-bottom: $bottom;
    margin-left: $left;
}
@mixin top($value){
    top: $value;
}

@mixin right($value){
    right: $value;
}

@mixin bottom($value){
    bottom: $value;
}

@mixin left($value){
    left: $value;
}

.m{
    &-b{
        &-20{
            @include margin-indv('bottom', 20px);
        }
    }
}
