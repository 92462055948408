@mixin transform($transforms) {
    transform: $transforms;
}

@mixin scale($xvalue, $yvalue){
    transform: scale($xvalue, $yvalue);
}

@mixin rotate($value){
    // transform: rotate($value);
    @include transform(rotate(#{$value}deg));
}

// translate
@mixin translate ($x, $y) {
    @include transform(translate($x, $y));
}

// skew
@mixin skew ($x, $y) {
    @include transform(skew(#{$x}deg, #{$y}deg));
}

//transform origin
@mixin transform-origin ($origin) {
        transform-origin: $origin;
}
