.cursor{
    &-not-allowed{
        cursor:not-allowed;
    }
    &-default{
        cursor: default;
    }

    &-pointer{
        cursor: pointer;
    }

    &-deny{
        pointer-events: none;
    }

    &-auto{
        cursor: auto;
    }

    &-grab{
        cursor: grab;
        cursor: -webkit-grab;
    }

    &-grabbing{
        cursor: -webkit-grabbing;
        cursor: grabbing;
    }

    &-resize{
        cursor: w-resize;
    }
}
