@mixin flex {
  display: flex;
}

@mixin display($display){
  display: $display;
}

@mixin position($position) {
  position: $position;
}

%position {
  &-relative {
    @include position($relative);
  }

  &-absolute {
    @include position($absolute);
  }

  &-fixed {
    @include position($fixed);
  }
}
