.card-tile{
    @include margin-indv('bottom', 1.8rem);

    &:last-child{
        @include padding-indv('bottom', 4%);
    }

    .card{
        @include border-radius(0);
        @include padding(16px);
        @include bgcolor($white);
        @include border-all(1px, solid, $light);
        transition: box-shadow .3s;

        &:hover,
        &:focus,
        &:active,
        &.tn-focus{
            @include box-shadow-all;
            transition: box-shadow .3s;

            .card{
                &-title{
                    .btn-title{
                        @extend %project-hover-underline;
                    }
                }
                
            }

            p{ 
                text-overflow: initial;
                white-space: normal;
            }

        }

        &-body{
            @include padding(0);
        }
        p{
            @include font-color($secondary);

            span{
                @include font-color($darkest);
            }
        }
        &-footer{
            @include padding(0);
            @include background(transparent);
            @include border-none(none);
        }
       
        &-title{
            @include font-size(20);
            @include font-color($black);
            overflow: hidden;
            height: 85px;
            line-height: 27px;

            @media screen and (max-width: $xxlarge){
                @include font-size(16);
            }

            .btn-title{
                @include font-color($black);

                &:hover,
                &:focus{
                    @extend %project-hover-underline;
                }
            }
        }
        &-subtitle{
            min-height: 21px;
        }
        &-text{
            height: 45px;
            overflow: hidden;
        }

        .more-options{
            @include display(inline-flex);
            @include margin-indv('bottom', 0);
            flex-direction: row;
            flex-wrap: nowrap;

            .list-inline-item{
                flex-grow: 1;

                .total-user{
                    @include font-color($secondary);

                    a{
                        @include font-color($secondary);
                    }
                }
            }
        }

        .dropdown{
            &__more{
                @include margin-indv('right', -16px);
                &.tooltip {
                    @include z-index(1040);
                }
                .dropdown-toggle{
                    &:after{
                        @include display(none);
                    }
                }
            }
        }

        &.success{
            @include border-color($primary);
            @include box-shadow-success;
        }
    }
}
