@mixin border-none($value){
    border: $value;
}

@mixin border-indv($direction, $width, $thickness, $color){
    border-#{$direction}: $width $thickness $color;
}

%no-border-bottom{
    border-bottom: none;
}

@mixin border-all($value, $thickness, $color){
    border: $value $thickness $color;
}

@mixin border-color($value) {
    border-color: $value;
}
