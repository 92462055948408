@mixin box-shadow($horz, $vert, $blur, $spread, $color, $inset: false) {
    @if $inset {
      box-shadow:inset $horz $vert $blur $spread $color;
    } @else {
      box-shadow: $horz $vert $blur $spread $color;
    }
}

@mixin box-shadow-dropdown{
    @include box-shadow(0, 8px, 16px, 0, rgba(0, 0, 0, .10));
}

@mixin box-shadow-all{
    @include box-shadow(0, 7px, 25px, 0, $light);
}

@mixin box-shadow-inset{
    @include box-shadow(0, 1px, 3px, 0, rgba(0, 0, 0, .10), true);
}

@mixin box-shadow-form-focus{
    box-shadow: 0 0 0 3px rgba(0,110,255,.60), inset 0 1px 3px 0 rgba(0,0,0,.10);
}

@mixin box-shadow-success{
    @include box-shadow(0, 0, 20px, 0, rgba(0,110,255,.30));
}

@mixin box-shadow-success-alert{
    @include box-shadow(0, 5px, 10px, 0, rgba(0,0,0,.20));
}

@mixin box-shadow-error-alert{
    @include box-shadow(0, 5px, 10px, 0, rgba(181,18,58,.20));
}

@mixin box-shadow-none {
    box-shadow: none;
}

@mixin box-shadow-card{
    @include box-shadow(2px, 2px, 4px, 0, rgba(0,0,0,0.1));
}
