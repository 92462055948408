a {
  @extend %anchor-transition;
  @include font-color($secondary);

  &:hover,
  &:focus,
  &:active {
    @include font-color($primary);
    text-decoration: none;
  }

  // &:focus {
  //   outline: 1px dashed $black;
  // }
}

div {
  &[role="combobox"] {
    &:focus {
      outline: 1px dashed $black;
    }
  }
}

p {
  &[role="textbox"] {
    &:focus {
      outline: 1px dashed $black;
    }
  }
}

body,
html {
  @extend .full-height;
  overflow-x: hidden;
}

body {
  @include font-size(14);
  @include font-color($black);
  font-family: $base-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;


}

h1,
h2 {
  @extend %font-heading-primary;
  @include margin-indv('bottom', 0);
}

.title {
  &-block {
    @include margin-indv('bottom', 0);

    .btn {
      &-back {
        @extend .hidden;
      }
    }
  }

  &-sub {
    @include font-size(14);
  }
}

.border {
  &-0 {
    border: none;
  }

  &-top {
    border-top: 1px solid fade-out($black, .9);

    &-0 {
      border-bottom: none;
    }
  }

  &-right {
    border-right: 1px solid fade-out($black, .9);

    &-0 {
      border-bottom: none;
    }
  }

  &-bottom {
    border-bottom: 1px solid fade-out($black, .9);

    &-0 {
      border-bottom: none;
    }
  }

  &-left {
    border-left: 1px solid fade-out($black, .9);

    &-0 {
      border-bottom: none;
    }
  }
}

.dropdown {
  &-toggle {
    &::after {
      width: 8px;
      height: 5px;
      vertical-align: .16em;
    }
  }
}

.form-row {
  @include margin-indv('left', 0);
  @include margin-indv('right', 0);
}

.container {
  &-fluid {
    @extend .full-height;
  }
}

.dim {
  @include opacity(.3);
}

.jumbotron {
  @include bgcolor($lightest);
  @include padding(1.2rem 0);
  @include border-all(1px, solid, $light);

  &-thin {
    @include padding(10px 0);
  }
}

.login-container {
  @include margin(40px, auto, 40px, auto);
  max-width: 520px;

}

// Icon Styling
.icon {
  &-rotate {

    //to make the font-icon rotate 90 degree
    &-90 {
      @include rotate(90deg);
      filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
    }

    //to make the font-icon rotate 180 degree
    &-180 {
      @include rotate(180);
      filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
    }

    //to make the font-icon rotate 270 degree
    &-270 {
      @include rotate(270deg);
      filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
    }
  }
}

//to make the font-icon flip horizontal & vertical
.icon-flip {
  &-flip {
    &-horizontal {
      @include scale(-1, 1);
      filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
    }

    &-vertical {
      @include scale(1, -1);
      filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
    }
  }
}

.align {
  &-btn {
    @include margin-indv('top', 21px);
  }

}

pre {
  font-family: $base-font;
  white-space: pre-wrap;
}

.owl {
  &-dt {
    &-timer {
      @include padding (0 .5em);
      height: 4.2em;

      &-content {
        .owl-dt-timer-input {
          @include font-size(16);
          height: 25px;
        }
      }
    }

    &-control {
      &-button {
        @include font-size(12);
      }
    }
  }
}

.onlyIEmsg {
  @extend %position-absolute;
  @include top(0);
  @include left(0);
  @include z-index(1000);
  @include bgcolor($secondary);
  width: 100%;
  height: 100%;
  background-color: transparentize($color: $secondary, $amount: .3);

  &.hideIEmsg {
    &.modal {
      @include display(none);
    }
  }

  &.showIEmsg {
    &.modal {
      @include display(block);
    }
  }

}

.preview-panel {
  @include right(0);

  &--nav {
    &-helper {
      @extend %position-absolute;
      @include bottom(-11px);
      @include left(0);
      width: 100%;
      @include font-size(10);
      @include font-color($darkest);

      @media screen and (max-width: $xxxlarge) {
        @include font-size(8);
        @include display(none);
      }

      @include media("screen", "<maxLarge") {
        @include display(none);
      }

      .notify-on {
        @extend %position-absolute;
        @include bgcolor($notify);
        @include border-radius(50%);
        @include margin-indv('right', 3px);
        @include margin-indv('top', -25px);
        @include top(0);
        @include right(0);
        width: 5px;
        height: 5px;
      }

    }
  }

  .node {
    &-navigate {
      @include position(relative);
      min-height: 40px;

      .btn {

        // @include padding(0px 6px 3px);
        em[class^='icon-'] {
          @include position(relative);
          @include top(2px);
        }

        &:disabled {
          em[class^='icon-'] {
            cursor: not-allowed;
            opacity: .5;
          }
        }
      }

    }
  }

  .btn {
    // @include padding(0px 6px 3px);

    em[class^='icon-'] {
      @include position(relative);
      @include top(2px);
    }

    &:disabled {
      em[class^='icon-'] {
        cursor: not-allowed;
        opacity: .5;
      }
    }
  }

}


.jrPage {
  span {
    font-size: 14px !important;
    font-family: $base-font !important;
  }
}

.uploader {
  &-loading {
    @include margin-indv('bottom', 50px);
    height: 90px;
  }
}

.tree-custom {
  .treeview {
    &-container {
      @include padding(0);
      @include margin-indv('right', -10px);
    }
  }
}

// braedcrumb
.bread-crumb {
  li {
    @include display(inline-block);
    @include font-color($darkest);
    @include font-size(12);
    @include padding-indv('right', 7px);

    &:after {
      @include margin-indv('left', 7px);
      @include font-size(8);
      font-family: $icon-font;
      content: '\e900';
    }

    &:last-child,
    &.active {
      &:after {
        @include margin-indv('left', 0);
        content: '';
      }
    }
  }
}

.table {
  th {
    &:hover {
      .dropdown {
        .filter {
          @extend %transition-fade-active;
        }
      }
    }

    &.active {
      .dropdown {
        .filter {
          @extend %transition-fade-active;
        }
      }
    }
  }
}



#treeViewContainer {
  .subject {
    @include display(none);
  }

  #taxonomyListingHeading {
    .view {
      &-title {
        @extend %font-weight-semi-bold;
      }
    }
  }
}

.overflowY-auto {
  overflow-x: hidden;
  overflow-y: auto;
}

.h2 {
  @include font-size(24);
}

.expend-row {
  &.authoring {
    &-header {
      @include margin-all(0 -45px);
      @include border-indv(bottom, 1px, solid, $light);
      @include bgcolor($white);
      @include position(relative);
      z-index: 2;

      .h2 {
        @include font-size(24);

        .title-locked {
          @include position(relative);
          @include top(4px);
          max-width: 100%;
        }
      }

      ul {
        @include margin-all(0);
      }

      .left-panel {
        // @include bgcolor($lightest);

        .ic-commet {
          @include margin-indv('left', 10px);
        }
      }

      .left-panel,
      .right-panel {
        @extend %position-relative;
        @include padding(14px 15px);
        @include z-index(2);
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }
}


.project-authoring {
  &-bg {
    height: 44px;
    max-width: 90%;

    @include media("screen", "<maxXxxlarge") {
      max-width: 85%;
    }

    .title-locked {
      max-width: 50%;

      @include media("screen", ">minXxlarge") {
        max-width: 75%;
      }

      @include media("screen", ">minXxxlarge") {
        max-width: 80%;
      }

    }
  }

  &__taxonomyEdit {
    form {
      @include margin-indv('top', 80px);
    }
  }

  &--tree {
    // @extend %position-fixed;
    @include bgcolor($lightest);
    @include padding-indv('right', 0);
    @include left(0);
    overflow-y: auto;

    .card {
      @include bgcolor($lightest);
    }
  }

  &-dropdown {
    @include right(-5px);
    @include top(-7px);

    &>a {
      @include position(relative);
      @include right(15px);
    }
  }

  .accordion {
    .associations_type_destination {
      @include border-indv('bottom', 1px, solid, $darker);
      @include margin-all(0 -9px);
    }
  }
}


//needs to be refactored
.public {
  &_review {
    &--view {
      @include border-indv('top', 1px, solid, $light);
      @include margin-indv('left', -45px);
      @include margin-indv('right', -45px);
    }

    &--tree-scroll {
      overflow-x: hidden;
      overflow-y: auto
    }

    &--node {
      flex: 0 0 50%;
      max-width: 50%;
    }

    &--comment {
      @include bgcolor($tertiary);
      @include right(0);
      flex: 0 0 3%;
      max-width: 3%;

      &-text {
        @include rotate(-90);
        @include display(block);
        @include margin(60px, auto, 0, auto);
        @include font-color($black);
        white-space: nowrap;
        height: 15px;

      }
    }

    .expend-row {
      @include box-shadow(0, 0, 16px, 0, rgba(0, 0, 0, .15));
      @include padding-indv('bottom', 4px);

      .left-panel {
        @include bgcolor($white);
      }
    }

    .preview {
      &-panel {
        @include right(3%);
        @include bgcolor($tertiary);
        @include border-indv('top', 1px, solid, $light);
        @include box-shadow(-5px, 6px, 16px, 0, rgba(0, 0, 0, .10));
        flex: 0 0 47%;
        max-width: 47%;

        .preview-panel {
          @include box-shadow-none;
          flex: 0;
          max-width: 100%;
        }
      }
    }

    .project {
      &-authoring {
        &--tree {
          @include bgcolor($white);

          .tree {
            &--details {
              .activated {
                @include bgcolor($tertiary);
              }
            }
          }
        }

        .card {
          @include bgcolor($white);
        }
      }
    }

    .card-header {
      &.activated {
        .node-text {
          a {
            @include font-color($primary);
          }
        }
      }
    }

    .accordion {
      @include box-shadow(0, 2px, 12px, 0, rgba(0, 0, 0, 0.1));
    }
  }
}

.node-navigate {
  min-height: 40px;
}

.parent-folder {
  @extend %font-weight-semi-bold;
  @include font-color($primary);
  @include font-size(12);
}

// Workflow

.workflow {
  @include padding-indv('left', 70px);

  &:before {
    @extend %position-absolute;
    @include top(70px);
    @include bottom(100px);
    @include left(99px);
    @include border-indv('left', 1px, solid, $dark);
    content: '';
    transform: translate(-50%);
  }

  &__start {
    @include margin-indv('bottom', 30px);
  }

  &__finish {
    @include margin-indv('top', 30px);
  }

  .add {
    &-step {
      &-button {
        @include position(relative);
        @include margin-indv('left', 75px);
        @include padding-indv('left', 60px);
        width: 58.33%;

        .btn {
          @include position(absolute);
          @include right(0);
          @include top(-55px);
        }
      }
    }
  }

  .drag {
    @include position(relative);
    @include padding-indv('top', 10px);
    @include margin-all(0 -10px 0 -10px);

    div[class^='col-'] {
      @include padding-indv('left', 10px);
      @include padding-indv('right', 10px);
    }

    &:hover {
      @include bgcolor($lighter);
    }

    .handle {
      @include position(absolute);
      @include left(-20px);
      @include top(16px);
    }
  }

  &-search-enabled {
    &:before {
      @include top(110px);
    }
  }
}

.mark {
  @include bgcolor(transparentize($warning, .5));
}

.lang-chip {
  @include border-radius(30px);
  @include border-all (1px, solid, $darker);
  @include padding(2px 10px);
  @include bgcolor($white);
  @include margin-indv('bottom', 7px);
}

//for the preloader backdrop
.preloader {
  &-backdrop {
    @include top(0);
    @include left(0);
    @include bottom(0);
    @include z-index(2);
    height: 100vh;
  }
}

//for the csv status popup
.csv_status_popup {
  @include bgcolor($white);
  @include border-all(1px, solid, $dark);
  @include border-radii(5px, 5px, 0, 0);
  @include bottom(0);
  @include right(15px);
  @include z-index(100);
  width: 350px;
  max-height: 500px;

  .header {
    @include bgcolor($secondary);
    @include border-radii(5px, 5px, 0, 0);

    &__title,
    .btn-icon {
      @include font-color($white);
    }

    button:focus {
      outline: 1px dashed #fff;
    }
  }

  .btn-collapse {
    &:after {
      @include position(absolute);
      @include font-size(8);
      @include font-weight(700);
      font-family: $icon-font;
      content: '\e903';
    }

    &[aria-expanded="false"] {
      &:after {
        @include top(11px);
        @include right(8px);
        transform: rotate(360deg);
      }
    }

    &[aria-expanded="true"] {
      &:after {
        @include top(8px);
        @include right(7px);
        transform: rotate(180deg);
      }
    }
  }
}


.cdk-virtual-scroll-viewport {
  overflow-y: scroll;
}


.text-dimed {
  @include font-color($darkest);
  @include font-weight(400);
}

.right-0 {
  @include right(0);
}
