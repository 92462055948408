.search{
// Search panel start
  &--node{
    @include padding-indv('right',0);
    .search{
      &-icon{
        @include float(left);
        @include margin-indv('top', -6px);
        
        img{
          width: 16px;
          height: 16px;
        }
      }
      
      &-field{
        @include margin-indv(left,30px);
        @include border-indv('bottom', 1px, solid, $darker);
        width: 0;
        -webkit-transition: width .2s ease-in-out;
        transition: width .2s ease-in-out;

        &.show-search{
          width: 160px;
        }
        
        input[type=text] {
          @include font-size(16);
          @include border-none(none);
          @include padding(0);
          width: 100%;
          box-sizing: border-box;
        }
      }
    }
  }

  &-inputfield{
    @include padding(5px 30px);
    @include font-color($black);

    &:placeholder{
      @include font-color($black);
    }
  }

  &-suggession{
      @include left(0);
      @include top(34px);
      @include border-all(1px, solid, $light);
      @include border-radius(5px);
      @include box-shadow-all;
      @include padding(10px 20px 0);
      @include z-index(100);
      width:620px;

      &-block{
        &--title{
          @include font-size(13);
          @include font-color($darkest-x);
        }

        .btn{
          @include font-color($black);
        }

        .term{
          max-width: 100px;
        }

        .filters{
          max-width: 175px;
        }

        .dim{
          @include font-color($darkest);
          opacity: 1;
        }
      }

      &-footer{
        @include font-size(12);
        @include margin-all(0 -20px);
        @include padding(4px 10px);
        @include border-indv('top', 1px, solid, $light);
      }
  }
  &-icon{
    @include padding(6px 10px);
  }

  &-clear{
    @include font-size (20);
    @include padding(2px 10px);
    @include bgcolor(transparent);
    @include right(16px);
    @include top(1px);
    @include border-none(none);
  }

  &-history{
    .card{
      @include display(block);
      @include font-color($darkest-x);
      @include padding(10px 15px);
      @include margin-indv('bottom', 5px);
      @include border-all(1px, solid, transparent);
      width: 100%;
      &:hover,
      &:focus{
        @include border-all(1px, solid, $light);
        @include box-shadow-all;
      }
      &-title{
        @include font-size(16);
        @include margin-all(0);
        span{
          @include font-size(10);
          @include font-color($darkest);
          @include padding-indv('left', 4px);
          max-width: 30%;
        }
        &-title{
          @include font-size(16);
          @include margin-all(0);
          span{
            @include font-size(10);
            @include font-color($darkest);
            @include padding-indv('left', 4px);
            max-width: 30%;
          }
          a{
            @include font-color($black);
            &:hover,
            &:focus{
              @include font-color($primary);
              text-decoration: underline;
            }
          }
        }
        .type{
          @include font-color($darkest);
        }
        .ic-go{
          width:20px;
        }
      }
    }
  }
// Search panel end

// Search result start
  &-result{
    @include margin-indv('top', 30px);

    // &-count{
    //   @include margin-all(0 -45px);
    //   @include padding(0 35px);
    // }

    &-header{
      width: 100%;
    }
    .panel{
      &-left{
        overflow-y: auto;
        overflow-x: hidden;
        
        h2{
          @include font-size(16);
          a{
            @include font-color($black);
            &:hover,
            &:focus{
              @include font-color($primary);
            }
          }
        }
        .grade{
          @include font-color($darkest);
          @include font-size(10);
          text-transform: uppercase;
        }
        .search-result{
          &-heading{
            .col-10{
              @include padding-indv('left', 6px);
            }
          }
          &-row{
            @include border-all(1px, solid, transparent);
            @include border-radius(4px);
            @include margin-indv('bottom', 4px);
            .col-10{
              @include padding-indv('left', 6px);
            }
            &:hover,
            &:focus,
            &.active{
              @include bgcolor($tertiary);
              @include border-all(1px, solid, $light);
              
              h2{
                @include font-color($primary);
              }
            }
          }  
        }

      }
      &-right{
        overflow-y: auto;
        overflow-x: hidden;
        .project-authoring{
          &__taxonomyEdit{
            form{
              @include margin-indv('top', 0);
            }
          }
        }
        .node{
          &-detail{
            @include border-all(1px, solid, $light);
            @include border-radius(4px);
            &-row{
              @include display(flex);
              @include border-indv('bottom', 1px, solid, $light);
              @include font-color($black);
              div[class^='col-']{
                @include padding-all(10px, auto, 8px, 10px);
              }
              .label{
                @include font-color($darkest-x);
                @include font-size(13);
              }
            }
            &-footer{
              @include box-shadow-inset;
              @include bgcolor($lighter);
              @include padding-all(5px, 10px, 5px, 10px);
              @include text-align(center);
              @include font-size(12);
                a{
                  @include font-color($primary);
                  text-decoration: underline;
                  &:hover,
                  &:focus{
                    text-decoration: none;
                  }
                }
            }
          }
          &-view{
            @include padding-indv('right', 50px);
            @include border-indv('left', 1px, solid, $light);

            &-button{
              @include bgcolor($lighter);
              @include border-radius-left(0);
              @include border-radius-bottom(0);
              @include right(-1px);
              @include top(-1px);
              height: 60px;
              line-height: 45px;
            }
          }
        }
      }
    }

    .card{
      @include display(block);
      @include font-size(13);
      @include font-color($darkest-x);
      @include padding(5px 15px);
      @include margin-indv('bottom', 5px);
      @include border-all(1px, solid, $light);
      width: 100%;

      &-title{
        max-width: 70%;
      }
      .taxonomy-name{
        max-width: 55%;
      }
      .assosiated-project{
        max-width: 65%;
      }
      .author-name{
        max-width: 65%;
      }
      h2{
        @include font-size(20);
        span{
          @include font-size(10);
          @include font-color($darkest);
          @include padding-indv('left', 4px);
          @include float(left);
          text-transform: uppercase;
          max-width: 30%;
        }
        a{
          @include font-color($black);
          &:hover,
          &:focus{
            @include font-color($primary);
            text-decoration: underline;
          }
        }
      }
      ul{
        li{
          @include margin-indv('left',10px);
        }
      }
      .text-truncate{
        @include display(inline-block);
        @include float(left);
        @include padding-indv('left', 4px);
      }
      .label{
        @include float(left);
      }
    }
    .accordion {
      .card{
        @include padding(0);
        &.collapse:not(.show) {
          display: none;
        }
      }
    }
  }
// Seardh result end

// Search filter start
  &-filter{
    @include font-size(13);
    // @include margin-all(0 -9% 0 -45px);
    // @include padding(12px 0 12px 52px);
    min-height: 46px;

    &:after{
      @extend %position-absolute;
      @include border-indv('bottom', 1px, solid, $light);
      @include left(-40px);
      @include bottom(0);
      width: 130%;
      content:""
    }
    .auto-complete{
      @include top(-9px);
      .custom-select {
        line-height: 1.65rem;
      }
      &__choice-tag{
        .tag{
          max-width: 100px;
        }
      }
    }
    .dropdown{
      @include margin-indv('right', 18px);
      // height: 16px;
      &:last-child{
        @include margin-indv('right', 0);
      }
    }
    &-auto-complete{
      @include position(absolute);
      @include top(-6px);
      @include z-index(100);
      width: 320px;
      .auto-complete{
        
        &__choice-tag{
          .tag{
            max-width: 90px;
          }
        }
      }
    }
    
  }
// Search filter end

//Advance seaerch tags
  .auto-complete{
    &__choice-tag{
      @include bgcolor($light);
      @include margin-indv('top', 2px);
      @include margin-indv('bottom', 2px);
      @include padding(0 6px);
      @include border-radius(4px);
      line-height: 12px;

      .tag{
        max-width: 200px;
      }
    }
  }
}

