.list-group{
    &-item{
        min-height: 36px;
        &:first-child,
        &:last-child{
            @include border-radius(0);
        }
        
        @include padding(5px 10px);
    }
    &.manage-set{
        .list-group-item{
            .set-name{
                max-width: 94%;
            } 
        }
    }
}
