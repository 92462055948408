%transition-fade {
    @extend .hidden;
    @include opacity(0);
    transition: visibility .5s, opacity .5s linear;
}

%transition-fade-active {
    @extend .visible;
    @include opacity(1);
}
%trasition-smooth{
    transition: all ease-in-out .3s;
}
