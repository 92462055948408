@import 'margin';
@import 'border';
@import 'font';
@import 'padding';
@import 'border-radius';
@import 'opacity';
@import 'box-shadow';
@import 'transition_fade';
@import 'components/cursor';

//General mixin
@mixin bgcolor($value) {
  background-color: $value;
}

@mixin background($value) {
  background: $value;
}

@mixin z-index($value) {
  z-index: $value;
}

@mixin float($value) {
  float: $value;
}

@mixin avatar($width, $height, $space-after) {
  @include border-radius(50%);
  @include float(left);
  @include margin-indv(right, $space-after);
  @include display(block);
  width: $width;
  height: $height;
  overflow: hidden;

  img {
    max-width: 100%;
  }
}

//placeholder mixins
%anchor-transition {
  -o-transition: color .3s ease-in, color .6s ease-out;
  -ms-transition: color .3s ease-in, color .6s ease-out;
  -moz-transition: color .3s ease-in, color .6s ease-out;
  -webkit-transition: color .3s ease-in, color .6s ease-out;
  transition: color .3s ease-in, color .6s ease-out;
}

%box-shadown-transition {
  -webkit-transition: box-shadow 500ms ease-out;
  -moz-transition: box-shadow 500ms ease-out;
  -o-transition: box-shadow 500ms ease-out;
  transition: box-shadow 500ms ease-out;
}

%font {
  &-heading-primary {
    @include font-size(22);
    @include font-color($black);
    line-height: 32px;

    @media screen and (min-width: 1024px) {
      @include font-size(24);
    }
  }

  &-weight {
    &-light {
      @include font-weight(100);
    }

    &-regular {
      @include font-weight(400);
    }

    &-medium {
      @include font-weight(600);
    }

    &-semi-bold {
      @include font-weight(700);
    }
  }
}

%project-hover-underline {
  @extend .text-underline;
  @include font-color($primary);
}

%text {
  &-overflow {
    &-hidden {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-wrapped {
      overflow-wrap: break-word;
      word-wrap: break-word;
      hyphens: auto;
      white-space: normal;
    }
  }
}

%border-transparent {
  @include border-color($transparent);
}

%arrow-pointer {
  @extend %position-absolute;
  content: ' ';
  margin-left: -5px;
  border-width: 7px;
  border-style: solid;
  border-color: $transparent $transparent rgba(0, 0, 0, .85) $transparent;
}

%bg {
  &-none {
    background: none;
  }

  &-transparent {
    @include bgcolor($transparent);
  }
}

%custom-checkbox {
  @include bgcolor($white);
  border: 1px solid $lighter;
}

%common-tab {
  @include border-indv('bottom', 1px, solid, $light);
  margin: 10px 0 30px 0;
}

%tab-selected {
  @include border-indv('bottom', 3px, solid, $notify);
  box-shadow: 0 8px 3px -2px rgba(255, 46, 99, .2);

  button,
  a {
    @include font-color($notify);
  }
}

%authoring-btn {
  @include bgcolor($notify);
  @include font-color($white);
}

%unread-msg-notification {
  @extend %position-relative;
  @include display(inline-block);
  @include bottom(10px);
  @include right(2px);
  @include border-radius(50%);
  @include bgcolor($notify);
  width: 5px;
  height: 5px;
}

@mixin text-align($value) {
  text-align: $value;
}

.full {
  &-width {
    width: 100%;
  }

  &-max-width {
    max-width: 100%;
  }

  &-height {
    height: 100%;
  }

  &-min-height {
    min-height: 100%;
  }

  &-max-height {
    max-height: 100%;
  }
}

.hidden {
  @include visible(hidden);
}

.visible {
  @include visible(visible);
}

.bg-lighter {
  @include bgcolor($lightest);
}

.hide {
  &-vScroll {
    overflow-y: hidden;
  }
}

//these will be replaced
.ic {

  &-search,
  &-report {
    max-width: 20px;
  }

  &-question {
    max-width: 30px;
  }

  &-stat,
  &-arrow {
    max-width: 16px;
  }

  &-bell,
  &-more {
    max-width: 14px;
  }

  &-folder,
  &-folder-dark {
    max-width: 15px;
  }

  &-tick {
    max-width: 16px;
  }

  &-dismiss,
  &-close,
  &-upload,
  &-bin {
    max-width: 27px;
  }

  &-back {
    max-width: 8px;
  }

  &-flip {
    @include font-size(17);
    @include font-color($darkest);
    // @include margin-indv('top', 6px);
    // @include margin-indv('right', 13px);
    // float: left;
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    transform: scaleX(-1);
  }
}

.icon-icon-more-horiz {
  @include font-size(6);
  @include font-color($darkest);
}

.preview-box {
  @include border-all(1px, solid, $darker);
  @include border-radius(3px);
  @include box-shadow-inset;
  max-height: 300px;
  min-height: 130px;
  overflow: auto;

  .tree {
    &--details {
      @include padding-all(20px, 0, 15px, 5px);
    }
  }
}

.close {
  @include opacity(1);
  @include font-size(12);
  text-shadow: none;

  &:hover,
  &:focus {
    @include opacity(.7);
    // color: $white;
  }
}

.push {
  &-top {
    &-5rem {
      @include margin-indv('top', 5rem);
    }
  }
}

.font {
  &-normal {
    @include font-size(14);
  }

  &-light {
    @extend %font-weight-light;
  }

  &-regular {
    @extend %font-weight-regular;
  }

  &-semibold {
    @extend %font-weight-medium;
  }

  &-bold {
    @extend %font-weight-semi-bold;
  }
}

%stop-drag {
  -webkit-user-drag: none;
  -moz-user-select: none;
}

img,
em {
  @extend %stop-drag;
}

.col {
  &-max-1 {
    max-width: 130px;
  }

  &-num {
    min-width: 30px;
  }

  &-data {
    flex: 0 0 90%;
    max-width: 90%;
  }
}

.max {
  &-w-50 {
    max-width: 50%;
  }
}

.mandatory {
  @include font-color($danger);
}

#time-picker-wrapper.active {
  z-index: 1051 !important;
}

.text {
  &-blue {
    @include font-color($primary);
  }

  &-gray {
    @include font-color($darkest);
  }

  &-pink {
    @include font-color($notify);
  }

  &-overflow {
    @extend %text-overflow-hidden;

    &__truncate {
      @extend .full-width;
    }
  }

  &-wrapped {
    @extend %text-overflow-wrapped;

    &-all {
      word-break: break-all;
    }
  }

  &-muted {
    @include font-size(13);
    @include font-color($darkest-x !important);
  }

  &-link {
    @include font-color($black);
  }

  &-underline {
    text-decoration: underline;

    &:hover,
    &:focus,
    &:active {
      @include font-color($primary);
      text-decoration: underline;
    }
  }

  &-editor {
    @include position(relative);
    @include z-index(0);
  }
}

.panel {
  @include padding(20px 30px);
  @include box-shadow-dropdown;
  @include border-radius(5px);
  @include border-all(1px, solid, $light);
}

.pl {
  &-3 {
    @include padding-indv('left', 24px);
  }

  &-4 {
    @include padding-indv('left', 32px);
  }

  &-5 {
    @include padding-indv('left', 40px);
  }
}

.block {
  &__bordered {
    @include border-all(1px, solid, $dark);
    @include border-radius(5px);
    @include padding(10px);
    overflow: auto;
  }

  &__dark {
    @include border-all(1px, solid, $light);
    @include padding(15px);
    @include background($lighter);
    @include border-radius(5px);
  }
}

.list {
  &__numbered {
    @include padding(0);
    list-style-position: inside;
  }
}

.taxonomy-preview {

  .associations,
  .inputfrom {
    @include padding(0);
  }
}

.hide-content {
  max-height: 60px;
  overflow: hidden;
}

.partner-name {
  @extend %text-overflow-wrapped;
}

.tab {
  &-container {
    // @include margin-all(0 -60px);
    @include padding(10px 15px 0 15px);
    @include border-indv('bottom', 1px, solid, $light);

    .title {
      @include font-size(16);
    }
  }
}

.custom {
  &-field {
    height: auto;
    min-height: 37px;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 73px;
  }
}

.tree-long-field {
  @include display(inline-block);
  // max-height:75px;
  overflow: hidden;
}

main {
  &:focus {
    outline: none;
  }
}

.confirmation {
  &-heading {
    @include font-size(24);
  }
}

.assciation {
  &-overlay {
    @extend .cursor-not-allowed;
    @include z-index(100);
    @include bgcolor(rgba(255, 255, 255, .6));
    @include display(none);
  }
}
