
.ngx-toolbar{
    .ngx-toolbar-set{
        .ngx-editor-button{
            &:hover,
            &:focus{
                @include bgcolor($light !important);
                transition: .2s;
            }
        }
    }
}

