//variables for the break points
$small: 560px;
$medium: 768px;
$large: 992px;
$xxlarge: 1024px;
$xxxlarge: 1200px;

@function translate-media-condition($c) {
  $condMap: (
    "screen": "only screen",
    "print": "only print",
    "retina": "(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-device-pixel-ratio: 1.5), (min-resolution: 120dpi)",

    ">minXxxlarge": "(min-width: #{$xxxlarge})",
    "<maxXxxlarge": "(max-width: #{$xxxlarge - 1})",

    ">minXxlarge": "(min-width: #{$xxlarge})",
    "<maxXxlarge": "(max-width: #{$xxlarge - 1})",

    ">minLarge": "(min-width: #{$large})",
    "<maxLarge": "(max-width: #{$large - 1})",

    ">minMedium": "(min-width: #{$medium})",
    "<maxMedium": "(max-width: #{$medium - 1})",

    ">minSmall": "(min-width: #{$small})",
    "<maxSmall": "(max-width: #{$small - 1})"
	);
    @return map-get( $condMap, $c );
}

// The media mixin
@mixin media($args...) {
  $query: "";
  @each $arg in $args {
    $op: "";
    @if ( $query != "" ) {
      $op: " and ";
    }
    $query: $query + $op + translate-media-condition($arg);
  }
  @media #{$query}  { @content; }
}

//how to use
//@include media( "screen", "variable"){ ... }
